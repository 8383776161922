import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    course: [],
    status: 'idle',
    error: null,
};

// Create an async thunk for fetching data
export const fetchCourse = createAsyncThunk('course', async (id) => {
    const token = localStorage.getItem("vedhvidhi-admin-token");
    const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/course/${id ?? ''}`, {
        method: id ? 'PATCH' : 'GET', // or POST depending on your API
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch course data');
    }

    const data = await response.json();
    return data;
});

const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourse.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCourse.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.course = action.payload;
            })
            .addCase(fetchCourse.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default courseSlice.reducer;