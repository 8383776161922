import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaPen } from "react-icons/fa";

function BasicInformation() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [dialCode, setDialCode] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [profilePicture, setProfilePicture] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        const formData = new FormData();

        formData.append('email', data.email);
        formData.append('full_name', data.full_name);
        formData.append('profile_bio', data.profile_bio);
        formData.append('dialcode', dialCode);
        formData.append('mobile_no', contactNo);

        if (profilePicture) {
            formData.append('profile_picture', profilePicture);
        }

        try {
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASEURL}/users/registerbyadmin/`,
                data: formData,
            };
            setLoading(true);
            const response = await axios.request(config);
            toast.success("Added Successfully.");
            if (response?.status) {
                navigate("/users-list");
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Team staff submission error: ', error);
            toast.error(error?.response?.data?.detail || "Something went wrong!");
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setProfilePicture(file);
    };

    const phoneInput = (value, data) => {
        const dialCode = `+${data?.dialCode}`;
        const mobileNumber = value?.substring(data?.dialCode?.length);
        setDialCode(dialCode);
        setContactNo(mobileNumber);
    };

    return (
        <>
            <div class="row align-items-center">
                <div class="border-0 mb-4">
                    <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                        <h3 class="fw-bold mb-0">Add a user</h3>
                        <button type="submit" className="btn btn-primary text-uppercase px-3">
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>

                    <div className="col-sm-12">
                        <div class="file-upload">
                            <label for="file-input">
                                <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="Profile Icon" class="icon" />

                                <div class="edit-icon">
                                    <FaPen />
                                </div>
                            </label>
                            <input type="file" id="file-input" />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Full Name <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="text"
                                name='full_name'
                                placeholder='Full name'
                                {...register('full_name', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.full_name && <span className="text-danger">{errors.full_name.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Email <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder='Enter your email'
                                {...register('email', {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                        message: "Invalid Email Address"
                                    },
                                    onChange: (e) => {
                                        e.target.value = e.target.value.toLowerCase();
                                    }
                                })}
                            />
                            {errors.email && <span className="text-danger">{errors.email.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <label htmlFor="phoneid" className="form-label">Phone <span className="text-danger">*</span></label>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                className: 'form-control w-100',
                                autoFocus: true,
                            }}
                            onChange={phoneInput}
                        />
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="date"
                                name='dob'
                                {...register('dob', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.dob && <span className="text-danger">{errors.dob.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">House No./Building No. <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="text"
                                name='house_no'
                                placeholder='House No./Building No.'
                                {...register('house_no', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.house_no && <span className="text-danger">{errors.house_no.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Street/Locality <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="text"
                                name='street'
                                placeholder='Street / Locality'
                                {...register('street', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.street && <span className="text-danger">{errors.street.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Landmark</label>
                            <input
                                className="form-control"
                                type="text"
                                name='landmark'
                                placeholder='Landmark'
                                {...register('landmark')}
                            />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">Pincode <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="text"
                                name='pincode'
                                placeholder='Pincode'
                                {...register('pincode', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.pincode && <span className="text-danger">{errors.pincode.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">City <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="text"
                                name='city'
                                placeholder='City'
                                {...register('city', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.city && <span className="text-danger">{errors.city.message}</span>}
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="form-label">State <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                type="text"
                                name='state'
                                placeholder='State'
                                {...register('state', {
                                    required: "This field is required",
                                })}
                            />
                            {errors.state && <span className="text-danger">{errors.state.message}</span>}
                        </div>
                    </div>
                    

                    <div className="col-12 mt-4">
                        {loading ?
                            <button type="submit" disabled className="btn btn-primary text-uppercase px-5">Loading...</button> :
                            <button type="submit" className="btn btn-primary text-uppercase px-5">SAVE</button>
                        }
                        <Link to="/users-list" type="button" className="btn btn-primary text-uppercase px-5 mx-2">CANCEL</Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default BasicInformation;