import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, } from "@mui/material";
import { fetchCategory } from "../../Redux/Reducers/CategoryReducer";
import axios from "axios";
import { Button } from "react-bootstrap";

function CategoryList() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const category = useSelector((state) => state.CategoryReducer.category);

  console.log(category, "wrpoighrwoigrw094209420942")

  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  useEffect(() => {
    dispatch(fetchCategory())
  }, [dispatch]);

  const rows = Array.isArray(category.data) ? category.data?.map((item, index) => {
    return {
      id: index + 1,
      categoryName: item?.categoryName ?? '-',
      _id: item?._id
    };
  }) : [];

  function deleteCategory(id) {
    const token = localStorage.getItem("vedhvidhi-admin-token");

    axios.delete(`${process.env.REACT_APP_BASEURL}/admin/category/${id}`, {
      headers: {
        Authorization: token,
      }
    })
      .then(response => {
        console.log(response, "Delete the response");
        if (response.data.status) {
          dispatch(fetchCategory());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }


  const handleClickOpen = (id) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (selectedUserId) {
      const userToDelete = category?.data?.find(row => row._id === selectedUserId);
      if (userToDelete && userToDelete._id) {
        deleteCategory(userToDelete._id);
      }
    }
    setOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 400 },
    { field: "categoryName", headerName: "Category Name", width: 500 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: ({ row }) => (
        <div>
          <IconButton
            component={Link}
            to={process.env.PUBLIC_URL + `/category-edit/${row._id}`}
          >
            <i className="icofont-edit text-danger"></i>
          </IconButton>
          <IconButton component={Link} onClick={() => handleClickOpen(row._id)}>
            <i className="icofont-ui-delete text-danger"></i>
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="body d-flex py-1">
      <div className="container-xxl">
        <PageHeader1
          pagetitle="Category List"
          righttitle="Add Category"
          link="/category-add"
          routebutton={true}
        />
        <div className="row g-0 mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div
                  id="myDataTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <Box sx={{ width: 1 }}>
                        <Box sx={{ height: 400 }}>
                          <DataGrid
                            columns={columns}
                            rows={rows}
                            getRowId={(row) => row._id}
                            disableColumnFilter
                            disableDensitySelector
                            slots={{ toolbar: GridToolbar }}
                            filterModel={filterModel}
                            onFilterModelChange={(newModel) =>
                              setFilterModel(newModel)
                            }
                            slotProps={{ toolbar: { showQuickFilter: true } }}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                              setColumnVisibilityModel(newModel)
                            }
                          />
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => {
              handleDelete();
            }} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default CategoryList;
