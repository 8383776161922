import React, { useState,useRef,useEffect } from 'react';
import Select, { components } from 'react-select';
import Toggle from '../Toggle/toggle';

// Options for the dropdown
const options = [{ label: 'Availability Timing', value: 'timing' }];

// Custom menu component to display your design
const CustomMenu = (props) => {
    const [timing, setTiming] = useState([{ start: '10:00 AM', end: '12:00 AM' }]);

    const addTimeSlot = () => {
        setTiming([...timing, { start: '', end: '' }]);
    };

    const removeTimeSlot = (index) => {
        const newTiming = timing.filter((_, i) => i !== index);
        setTiming(newTiming);
    };

    const attachStopPropagation = (element) => {
        return React.cloneElement(element, {
            onFocus: (e) => e.stopPropagation(),
            onClick: (e) => e.stopPropagation(),
            onMouseDown: (e) => e.stopPropagation(),
            onKeyDown: (e) => e.stopPropagation(),
            onChange:(e) => e.stopPropagation()
        });
    };

    // const attachStopPropagation = (element) => {
    //     return React.cloneElement(element, {
    //         onClick: (e) => e.stopPropagation(),
    //     });
    // };

    return (
        <components.Menu {...props}
        // onClick={
        //     (e) => e.stopPropagation()
        // }
        // onChange={
        //     (e) => e.stopPropagation()
        // }
        // onFocus={
        //     (e) => e.stopPropagation()
        // }
        // onMouseDown={
        //     (e) => e.stopPropagation()
        // }
        // onKeyDown={
        //     (e) => e.stopPropagation()
        // }
        >
            <div className='availTime_main'>
                <div className='dateBox'>
                    <ul>
                        <li>
                            <span className='day'>FRI</span>
                            <span className='date'>12</span>
                        </li>
                        <li>
                            <span className='day'>FRI</span>
                            <span className='date'>12</span>
                        </li>
                        <li>
                            <span className='day'>FRI</span>
                            <span className='date'>12</span>
                        </li>
                        <li>
                            <span className='day'>FRI</span>
                            <span className='date'>12</span>
                        </li>
                        <li>
                            <span className='day'>FRI</span>
                            <span className='date'>12</span>
                        </li>
                        <li>
                            <span className='day'>FRI</span>
                            <span className='date'>12</span>
                        </li>
                        <li>
                            <span className='day'>FRI</span>
                            <span className='date'>12</span>
                        </li>
                    </ul>
                </div>
                <div className='mainBox'>
                    <div className='selectDayBox'>
                        <span className='allDay'>Select for all days</span>
                        <Toggle />
                    </div>
                    <div class="time-selector">
                        <div class="time-row">
                            <div class="time-group">
                                <label>Start time</label>
                                {attachStopPropagation(
                                    <input className='form-control'
                                        onChange={(e) => {
                                            e.stopPropagation()
                                        }}
                                        type="text" placeholder="10:00 AM" />
                                )}
                            </div>
                            <div class="time-group">
                                <label>End time</label>
                                {attachStopPropagation(
                                    <input className='form-control'
                                        onChange={(e) => {
                                            e.stopPropagation()
                                        }}
                                        type="text" placeholder="10:00 AM" />
                                )}
                            </div>
                            <button class="add-btn btn-primary">+</button>
                        </div>

                        <div class="time-row">
                            <div class="time-group">
                                {attachStopPropagation(
                                    <input className='form-control'
                                        onChange={(e) => {
                                            e.stopPropagation()
                                        }}
                                        type="text" placeholder="10:00 AM" />
                                )}
                            </div>
                            <div class="time-group">
                                {attachStopPropagation(
                                    <input className='form-control'
                                        onChange={(e) => {
                                            e.stopPropagation()
                                        }}
                                        type="text" placeholder="10:00 AM" />
                                )}
                            </div>
                            <button class="remove-btn btn-primary">−</button>
                        </div>
                    </div>
                </div>

            </div>
        </components.Menu>
    );
};

// Main component
const AvailabilitySelect = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleMenuToggle = () => setMenuIsOpen((prev) => !prev);
    const selectRef = useRef(null);
    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setMenuIsOpen(false); // Close the dropdown if clicked outside
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className='availPosition_relative'>
            <div className='availPosition_absolute' onClick={handleMenuToggle} ref={selectRef}>
                drop
            </div>
            <Select
                options={options}
                isMulti={false}
                menuIsOpen={menuIsOpen}
                components={{ Menu: CustomMenu }} // Attach the custom menu here
                placeholder="Availability Timing"
                closeMenuOnSelect={false} // Keeps the menu open for multi-selection
                hideSelectedOptions={false}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
            />
        </div>
    );
};

export default AvailabilitySelect;
