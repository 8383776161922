import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    banner: [],
    status: 'idle',
    error: null,
};

// Create an async thunk for fetching data
export const fetchBanner = createAsyncThunk('banner/fetch', async () => {
    const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/banner`, {
        method: "GET",
    });

    if (!response.ok) {
        throw new Error('Failed to fetch banners');
    }

    const data = await response.json();
    return data;
});


const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBanner.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchBanner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                state.banner = action.payload;
            })
            .addCase(fetchBanner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default bannerSlice.reducer;