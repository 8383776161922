import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
    review: [],
    status: 'idle',
    error: null,
};

// Create an async thunk for fetching data
export const fetchreview = createAsyncThunk('review', async (id) => {
    const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/review/${id ? 'review_id=' : ''}${id ?? ''}`);
    const data = await response.json();
    return data;
});

// export const editreview = createAsyncThunk('review', async (id) => {
//     const response = await fetch(`${process.env.REACT_APP_BASEURL}/review/reviews/${id ? 'review_id=' : ''}${id ?? ''}`);
//     const data = await response.json();
//     return data;
// });

const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchreview.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchreview.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Add the fetched products to the state
                state.review = action.payload;
            })
            .addCase(fetchreview.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default reviewSlice.reducer;