import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../Redux/Reducers/ProductReducer';
import { fetchSubCategory } from '../../Redux/Reducers/SubCategoryReducer';
import { fetchCommission } from '../../Redux/Reducers/CommissionReducer';
import { BsCloudUpload } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import AvailabilitySelect from './AvailabilitySelect';


function AstrolgerAdd() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const subCategory = useSelector((state) => state?.SubCategoryReducer?.subCategory);
    const products = useSelector((state) => state?.ProductReducer?.products);
    const commission = useSelector((state) => state?.CommissionReducer?.commission);
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let localData = JSON.parse(localStorage.getItem("vedhvidhi-admin-data"));

    useEffect(() => {
        dispatch(fetchSubCategory());
        dispatch(fetchProducts());
        dispatch(fetchCommission(localData._id));
    }, [dispatch]);

    useEffect(() => {
        if (selectedCategory) {
            setFilteredSubCategories(subCategory?.filter(sc => sc?.categoryId?._id === selectedCategory?.value));
            setFilteredProducts([]);
            setSelectedSubCategory(null);
        } else {
            setFilteredSubCategories([]);
            setFilteredProducts([]);
        }
    }, [selectedCategory, subCategory]);

    useEffect(() => {
        if (selectedSubCategory) {
            setFilteredProducts(products?.filter(p => p?.subCategoryId?._id === selectedSubCategory?.value));
        } else {
            setFilteredProducts([]);
        }
    }, [selectedSubCategory, products]);

    const onSubmit = async (data) => {
        data.sellerId = localData?._id
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/coupon`, data, {
                headers: { 'Content-Type': 'application/json' }
            });
            toast.success(response.data.message);
            if (response.data.status) {
                navigate("/coupons-list");
            }
        } catch (error) {
            toast.error(error?.response?.data || 'Something went wrong!');
        } finally {
            setIsLoading(false);
        }
    };

    const options = [
        { value: 'english', label: 'English' },
        { value: 'hindi', label: 'Hindi' },
        { value: 'Tamil', label: 'Tamil' }
    ]
    const appointOption1 = [
        { value: 'onlineConsultation', label: 'Online Consultation' },
        { value: 'physicalRate', label: 'Physical Rate' }
    ]


    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleChange = (selected) => {
        setSelectedOptions(selected || []);
    };

    const CustomOption = (props) => {
        const { data, isSelected, innerRef, innerProps } = props;

        return (
            <div className='hoverSelect_clr' ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px 10px', cursor: 'pointer' }}>
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => { }} // React-select manages state
                    style={{ marginRight: '6px' }}
                />
                <label>{data.label}</label>
            </div>
        );
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={true} duration="10000" />
            <div>
                <div class="row align-items-center">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Personal Information</h3>
                            <button type="submit" className="btn btn-primary text-uppercase px-3" disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {/* <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-6">
                            <label className="form-label">Category</label>
                            <span style={{ color: "red" }}>*</span>
                            <Select
                                options={Array.from(new Map(commission?.map(cat => [cat.categoryId._id, cat])).values())
                                    ?.map(cat => ({
                                        value: cat.categoryId._id,
                                        label: cat.categoryId.categoryName
                                    }))}
                                placeholder="Select Category"
                                onChange={(selectedOption) => {
                                    setSelectedCategory(selectedOption);
                                    setValue('categoryId', selectedOption.value);
                                }}
                            />
                            {errors.categoryId && <span className="text-danger">{errors.categoryId.message}</span>}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Sub Category</label>
                            <span style={{ color: "red" }}>*</span>
                            <Select
                                options={filteredSubCategories.map(sCat => ({
                                    value: sCat?._id,
                                    label: sCat?.subCategoryName
                                }))}
                                placeholder="Select Sub Category"
                                onChange={(selectedOption) => {
                                    setSelectedSubCategory(selectedOption);
                                    setValue('subCategoryId', selectedOption.value);
                                }}
                            />
                            {errors.subCategoryId && <span className="text-danger">{errors.subCategoryId.message}</span>}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Product</label>
                            <span style={{ color: "red" }}>*</span>
                            <Select
                                options={filteredProducts?.map(prod => ({
                                    value: prod._id,
                                    label: prod.productName
                                }))}
                                placeholder="Select Product"
                                onChange={(selectedOption) => setValue('productsId', [selectedOption.value])}
                            />
                            {errors.productsId && <span className="text-danger">{errors.productsId.message}</span>}
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">Coupon Code</label> <span className="text-danger">*</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder='Enter coupon name'
                                    {...register('couponCodeName', {
                                        required: 'Coupon Name is required',
                                    })}
                                    onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                                />
                                {errors.couponCodeName && <span className="text-danger">{errors.couponCodeName.message}</span>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">Coupon Title</label> <span className="text-danger">*</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder='Enter coupon title'
                                    {...register('couponTitle', {
                                        required: 'Coupon Title is required',
                                    })}
                                />
                                {errors.couponTitle && <span className="text-danger">{errors.couponTitle.message}</span>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">Discount value</label> <span className="text-danger">*</span>
                                <input
                                    className="form-control"
                                    type="number"
                                    min="0"
                                    placeholder='Enter discount value'
                                    {...register('discountValue', {
                                        required: 'Discount value is required',
                                    })}
                                />
                                {errors.discountValue && <span className="text-danger">{errors.discountValue.message}</span>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">Start date</label> <span className="text-danger">*</span>
                                <input
                                    min={new Date().toISOString().split("T")[0]}
                                    className="form-control"
                                    type="date"
                                    {...register('startDate', {
                                        required: 'Start Date is required',
                                    })}
                                />
                                {errors.startDate && <span className="text-danger">{errors.startDate.message}</span>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">End date</label> <span className="text-danger">*</span>
                                <input
                                    min={new Date().toISOString().split("T")[0]}
                                    className="form-control"
                                    type="date"
                                    {...register('endDate', {
                                        required: 'End date is required',
                                    })}
                                />
                                {errors.endDate && <span className="text-danger">{errors.endDate.message}</span>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label className="form-label">Min. order value</label> <span className="text-danger">*</span>
                                <input
                                    className="form-control"
                                    min="0"
                                    type="number"
                                    placeholder='Enter minimum order value'
                                    {...register('minOrderVal', {
                                        required: 'Min order value is required',
                                    })}
                                />
                                {errors.minOrderVal && <span className="text-danger">{errors.minOrderVal.message}</span>}
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <button type="submit" className="btn btn-primary text-uppercase px-3" disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save'}
                            </button>
                            <Link to="/coupons-list" className="btn btn-secondary text-uppercase px-3 mx-2">Cancel</Link>
                        </div>
                    </form> */}
                    <form className='row g-4'>
                        <div className="col-sm-6">
                            <label className="form-label">Full Name
                                {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Your Name'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Email Id</label>
                            <input
                                className="form-control"
                                type="email"
                                placeholder='Your E-mail'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">City</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Enter Your City'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">State</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Enter Your State'
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Pincode</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder='Enter Your Pincode'
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Aadhar No.</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder='Enter Your Aadhar No.'
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Pan card No.</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder='Enter Your Pan card No.'
                            />
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Upload Aadhar Front & back photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Upload Aadhar photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Upload Pan card photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Upload aadhar photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Address</label>
                            <textarea className="form-control" style={{ minHeight: '80px' }}
                                type="file"
                                placeholder='Type Your Address'></textarea>
                        </div>
                    </form>
                </div>
            </div>

            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Professional Information</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form className='row g-4'>
                        <div className="col-sm-6">
                            <label className="form-label">Education Qualification</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Education Qualification'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Language</label>
                            <Select
                                options={options}
                                isMulti
                                value={selectedOptions}
                                // menuIsOpen={true}
                                onChange={handleChange}
                                closeMenuOnSelect={false} // Keeps the menu open for multi-selection
                                hideSelectedOptions={false}
                                components={{ Option: CustomOption }}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Professional Expertise</label>
                            <Select
                                options={options}
                                isMulti
                                value={selectedOptions}
                                // menuIsOpen={true}
                                onChange={handleChange}
                                closeMenuOnSelect={false} // Keeps the menu open for multi-selection
                                hideSelectedOptions={false}
                                components={{ Option: CustomOption }}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Availability Timing</label>
                            <AvailabilitySelect />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Professional Experience years</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Professional Experience years'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">GST No.</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Enter GST No'
                            />
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Upload Certificate</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Upload Certificate</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Upload GST Certificate</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Upload GST Certificate</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Fees/Rate</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form className='row g-4'>
                        <div className="col-sm-12">
                            <label className="form-label">Chat
                                {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <div className="row">
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Per Minute Rate'
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Offer Price Per Minute'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Call
                                {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <div className="row">
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Per Minute Rate'
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Offer Price Per Minute'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row g-4">
                                <div className="col-sm-12">
                                    <label className="form-label">Appointment</label>
                                    <Select options={appointOption1} />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Video Call Per Minute Rate'
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Video Call Per Minute Rate'
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Physical Price Per Minute'
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder='Physical Price Per Minute'
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Bank Details</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form className='row g-4'>
                        <div className="col-sm-4">
                            <label className="form-label">Name of Bank
                                {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Name of Bank'
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">IFSC Code</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='IFSC Code'
                            />
                        </div>
                        <div className="col-sm-4">
                            <label className="form-label">Account Number</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Account Number'
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">Billing Details</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form className='row g-4'>
                        <div className="col-sm-12">
                            <label className="form-label">PAN Card</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='PAN Card'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Aadhar Card</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='Aadhar Card'
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">GST Number (Optional)</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder='GST Number '
                            />
                        </div>
                    </form>
                </div>
            </div>

            <div>
                <div class="row align-items-center mt-4">
                    <div class="border-0 mb-4">
                        <div class="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 class="fw-bold mb-0">About Me</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form className='row g-4'>
                        <div className="col-sm-12">
                            <label className="form-label">Profile Bio</label>
                            <textarea style={{ minHeight: '80px' }} className='form-control' placeholder='Profiel Bio...'></textarea>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">My Video</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">My Video</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <video controls preload="none">
                                            <source src="/assets/video/Video.mp4" type="video/mp4" />
                                            {/* <track
                                                    src="/path/to/captions.vtt"
                                                    kind="subtitles"
                                                    srcLang="en"
                                                    label="English"
                                                /> */}
                                            Your browser does not support the video tag.
                                        </video>
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <video controls preload="none">
                                            <source src="/assets/video/Video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Gallery Photo</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Gallery Photo</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label">Certificate Gallery</label>
                            <div className="upload-container">
                                <label htmlFor="file-upload" className="upload-label">Certificate Gallery</label>
                                <label htmlFor="file-upload">
                                    <span className="upload-icon"><BsCloudUpload /></span>
                                </label>
                                <input id="file-upload" type="file" />
                            </div>

                            <div className="review_imgCardMain">
                                <ul>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                    <li>
                                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" alt="img" />
                                        <button>
                                            <IoIosClose />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <button type="submit" className="btn btn-primary text-uppercase px-3" disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save'}
                            </button>
                            <Link to="/coupons-list" className="btn btn-secondary text-uppercase px-3 mx-2">Cancel</Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AstrolgerAdd;