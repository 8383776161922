import React, { useEffect, useState } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import defaultImg from "../../assets/images/product/defaultImg.svg";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'; // Make sure axios is installed
import { fetchBanner } from '../../Redux/Reducers/BannerReducer'; // Assuming you have this action
import toast from 'react-hot-toast';
import { uploadImagecertifates } from '../../Redux/Actions/Action';

function CourseBanner() {
    const dispatch = useDispatch();

    const topBanner = useSelector(state => state?.BannerReducer?.banner?.data?.topBanner);

    // Initialize state for up to 6 images, with null as placeholders
    const initialImages = Array(6).fill(null);
    const [localImages, setLocalImages] = useState(initialImages);
    const [loading, setLoading] = useState(false);

    // Check if all images are filled with valid URLs
    const allImagesFilled = localImages?.every(img => img !== null);

    useEffect(() => {
        dispatch(fetchBanner());
    }, [dispatch]);

    // If there's a banner fetched, set it to the local images state
    useEffect(() => {
        if (topBanner && topBanner.length > 0) {
            const updatedImages = [...topBanner, ...Array(6 - topBanner.length).fill(null)];
            setLocalImages(updatedImages);
        }
    }, [topBanner]);

    const handleInputChange = async (event) => {
        setLoading(true);
        const files = Array.from(event.target.files);
        const updatedImages = [...localImages];

        // Upload images using the API and update state
        for (let file of files) {
            const uploadedImageUrl = await dispatch(uploadImagecertifates(file));
            const emptyIndex = updatedImages.indexOf(null);

            if (emptyIndex !== -1) {
                updatedImages[emptyIndex] = uploadedImageUrl;
            }
        }

        setLoading(false);
        setLocalImages(updatedImages);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = localImages.map((img, imgIndex) => (imgIndex === index ? null : img));
        setLocalImages(updatedImages);
    };

    const handleSave = async () => {
        const payload = {
            topBanner: localImages.filter(img => img !== null), // Send only live URLs, not defaultImg
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/admin/banner`, payload);
            if (response.data.status) {
                toast.success('Banner updated successfully!');
            } else {
                toast.error('Failed to update banner');
            }
        } catch (error) {
            console.error('Error updating banner:', error);
            toast.error('Failed to update banner');
        }
    };

    return (
        <>
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold">Course Banner
                    <span style={{ color: "red" }}>*</span>
                </h6>
            </div>
            <div className="card-body">
                <form>
                    <div className="row g-3 align-items-center">
                        <div className="col-md-12">
                            <small className="d-block text-muted mb-2">Select up to 6 images only.</small>

                            <div id='create-token' className='dropzone'>
                                <div className='dz-message d-flex align-items-center justify-content-center flex-column'>
                                    <i className="fa fa-picture-o m-0" aria-hidden="true"></i>
                                    <h5 style={{ fontSize: '17px', color: '#7258db', fontWeight: '600', marginTop: '8px' }}>
                                        {loading ? "Uploading..." : "Upload a file"}
                                    </h5>
                                    <h6 style={{ fontSize: '14px' }}>PNG, JPG, WEBP, SVG...</h6>
                                </div>
                                <input
                                    id='filesize'
                                    onChange={handleInputChange}
                                    name="file"
                                    type="file"
                                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff, .mp4, .webm, .mp3, .wav, .ogg, .glb"
                                    multiple
                                    disabled={allImagesFilled} // Disable input if all images are filled
                                />
                            </div>
                        </div>

                        {/* Display images */}
                        {localImages.map((image, index) => (
                            <div className='col-lg-2 col-md-4' key={index}>
                                <div className='position-relative border p-3' style={{ borderRadius: "0.95rem" }}>
                                    {image ? (
                                        <>
                                            <img className='img-fluid' src={image} alt={`uploaded-img-${index}`} />
                                            <button
                                                className='position-absolute end-0 top-0 padding-0 pt-1 bg-transparent border-0'
                                                onClick={() => handleRemoveImage(index)}>
                                                <IoIosCloseCircle style={{ fontSize: '26px', color: "#727070" }} />
                                            </button>
                                        </>
                                    ) : (
                                        <img className='img-fluid' src={defaultImg} alt="default" />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-4">
                        <button type="button" className="btn btn-primary text-uppercase px-5" onClick={handleSave}>SAVE</button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default CourseBanner;