// import React from 'react';
// import AddModal from './components/common/AddModal';
// import Sidebar from './components/common/Sidebar';
// import AuthIndex from "./screens/AuthIndex";
// import MainIndex from './screens/MainIndex';

// function App(props) {
//   const activekey = () => {
//     var res = window.location.pathname
//     var baseUrl = process.env.PUBLIC_URL
//     baseUrl = baseUrl.split("/");
//     res = res.split("/");
//     res = res.length > 0 ? res[baseUrl.length] : "/";
//     res = res ? "/" + res : "/";
//     const activeKey1 = res;
//     return activeKey1
//   }
//   if ((activekey() === '/sign-in' || activekey() === '/sign-up' || activekey() === '/reset-password' ||  activekey() === '/verification/:id' || activekey() === '/page-404') && !localStorage.getItem('vedhvidhi-admin-token')) {
//     return (
//       <div id="ebazar-layout" className='theme-blue'>
//         <AuthIndex />
//       </div>
//     );
//   }
//   return (
//     <div id="ebazar-layout" className='theme-blue'>
//       <Sidebar activekey={activekey()} history={props.history} />
//       <AddModal />
//       <MainIndex activekey={activekey()} />
//     </div>
//   )
// }
// export default App;




import React from 'react';
import { useLocation } from 'react-router-dom';
import AddModal from './components/common/AddModal';
import Sidebar from './components/common/Sidebar';
import AuthIndex from "./screens/AuthIndex";
import MainIndex from './screens/MainIndex';

function App(props) {
  const location = useLocation();

  const activekey = () => location.pathname;

  const authRoutes = ['/sign-in', '/sign-up', '/reset-password', '/verification/:id', '/page-404'];

  // Corrected local storage check
  if (authRoutes.includes(activekey()) && !localStorage.getItem('vedhvidhi-admin-token')) {
    return (
      <div id="ebazar-layout" className='theme-blue'>
        <AuthIndex />
      </div>
    );
  }

  return (
    <div id="ebazar-layout" className='theme-blue'>
      <Sidebar activekey={activekey()} history={props.history} />
      <AddModal />
      <MainIndex activekey={activekey()} />
    </div>
  );
}

export default App;



