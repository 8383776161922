import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress
} from '@mui/material';
import userImg from "../../assets/images/user.svg";
import PageHeader1 from '../../components/common/PageHeader1';

function AstrologerList() {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    let localData = JSON.parse(localStorage.getItem("vedhvidhi-admin-data"));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASEURL}/?sellerId=${localData?._id}`);
                // const data = response?.data?.result?.map((item, index) => ({
                //     id: index + 1,
                //     image: item?.image === "" ? userImg : item?.image,
                //     firstName: item?.firstName,
                //     lastName: item?.lastName,
                //     email: item?.email,
                //     contactNumber: item?.contactNumber,
                //     designation: item?.designation,
                //     professionalSkills: item?.professionalSkills,
                //     status: true,
                //     _id: item?._id
                // }));
                setRows([]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [!open]);

    function deleteTeamMember(id) {
        axios.delete(`${process.env.REACT_APP_BASEURL}/staff_deleteprofile`, {
            params: { id }
        })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleClickOpen = (userId) => {
        setSelectedUserId(userId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        if (selectedUserId) {
            const userToDelete = rows.find(row => row.id === selectedUserId);
            if (userToDelete && userToDelete._id) {
                deleteTeamMember(userToDelete._id);
            }
        }
        setOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'email', headerName: 'Email', type: 'email', width: 200 },
        { field: 'subject', headerName: 'Subject', type: 'tel', width: 200 },
        { field: 'description', headerName: 'Description', width: 250 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ row }) => (
                <div>
                    <IconButton component={Link} to={process.env.PUBLIC_URL + `/users-edit/${row?._id}`}>
                        <i className="icofont-edit text-danger"></i>
                    </IconButton>
                    <IconButton component="button"
                        onClick={() => {
                            handleClickOpen(row.id)
                        }}>
                        <i className="icofont-ui-delete text-danger"></i>
                    </IconButton>
                </div >
            ),
        },
    ];

    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [''],
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    return (
        <div className="body d-flex py-1">
            <div className="container-xxl">
            <PageHeader1 righttitle='Add Astrologer' link='/astrologer-add' routebutton={true} />
                <div className="row g-0 mb-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Box sx={{ width: 1 }}>
                                                <Box sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {loading ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <DataGrid
                                                            columns={columns}
                                                            rows={rows}
                                                            disableColumnFilter
                                                            disableDensitySelector
                                                            slots={{ toolbar: GridToolbar }}
                                                            filterModel={filterModel}
                                                            onFilterModelChange={(newModel) => setFilterModel(newModel)}
                                                            slotProps={{ toolbar: { showQuickFilter: true } }}
                                                            columnVisibilityModel={columnVisibilityModel}
                                                            onColumnVisibilityModelChange={(newModel) =>
                                                                setColumnVisibilityModel(newModel)
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        handleDelete();
                    }} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AstrologerList;