import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { Button } from "react-bootstrap";
import { fetchInstructorLead } from "../../Redux/Reducers/InstructorLeadsReducer";

function InstructorLeads() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const leads = useSelector((state) => state?.InstructorLeadsReducer?.instructors);
  const loading = useSelector((state) => state?.InstructorLeadsReducer?.loading);

  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  useEffect(() => {
    dispatch(fetchInstructorLead());
  }, [dispatch]);

  const rows = Array.isArray(leads)
    ? leads.map((item, index) => ({
        id: item?._id || `temp-id-${index}`, // Ensure unique id for every row
        sono: index + 1,
        name: item?.name || "-",
        mobile: item?.mobile || "-",
        email: item?.email || "-",
        expertise: item?.expertise || "-",
      }))
    : [];

  function deleteLead(id) {
    const token = localStorage.getItem("vedhvidhi-admin-token");

    axios
      .delete(`${process.env.REACT_APP_BASEURL}/admin/instructor/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response, "Delete the response");
        if (response.data.statusCode) {
          dispatch(fetchInstructorLead());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleClickOpen = (id) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (selectedUserId) {
      deleteLead(selectedUserId);
    }
    setOpen(false);
  };

  const columns = [
    { field: "sono", headerName: "ID", width: 150 },
    { field: "name", headerName: "Name", width: 180 },
    { field: "mobile", headerName: "Mobile No.", width: 180 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "expertise", headerName: "Expertise", width: 260 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: ({ row }) => (
        <div>
          <IconButton onClick={() => handleClickOpen(row.id)}>
            <i className="icofont-ui-delete text-danger"></i>
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="body d-flex py-1">
      <div className="container-xxl">
        <div className="row g-0 mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div
                  id="myDataTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <Box sx={{ width: 1 }}>
                        <Box
                          sx={{
                            height: 400,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <DataGrid
                              columns={columns}
                              rows={rows}
                              getRowId={(row) => row.id} 
                              disableColumnFilter
                              disableDensitySelector
                              slots={{ toolbar: GridToolbar }}
                              filterModel={filterModel}
                              onFilterModelChange={(newModel) =>
                                setFilterModel(newModel)
                              }
                              slotProps={{ toolbar: { showQuickFilter: true } }}
                              columnVisibilityModel={columnVisibilityModel}
                              onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                              }
                            />
                          )}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default InstructorLeads;
