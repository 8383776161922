import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Tabs, Tab } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress
} from '@mui/material';
import PageHeader1 from '../../components/common/PageHeader1';
import { fetchCourse } from '../../Redux/Reducers/CourseReducer';
import { useDispatch, useSelector } from 'react-redux';

function CourseList() {
    let dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0); 
    const course = useSelector((state) => state.CourseReducer?.course);

    useEffect(() => {
        dispatch(fetchCourse());
    }, [dispatch]);

    function deleteCourse(id) {
        axios.delete(`${process.env.REACT_APP_BASEURL}/admin/course/${id}`)
            .then(() => {
                dispatch(fetchCourse());
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleClickOpen = (userId) => {
        setSelectedUserId(userId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        if (selectedUserId) {
            const userToDelete = course.data?.find(row => row._id === selectedUserId);
            if (userToDelete && userToDelete._id) {
                deleteCourse(userToDelete._id);
            }
        }
        setOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'title', headerName: 'Course Title', width: 250 },
        { field: 'batchDetails', headerName: 'No. of batches', width: 250 },
        { field: `tutorsName`, headerName: `Tutor's name`, width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ row }) => (
                <div>
                    <IconButton
            component={Link}
            to={{
                pathname: `${process.env.PUBLIC_URL}/course-edit/${row?._id}`,
                state: { step: row?.step },
            }}
        >
            <i className="icofont-edit text-danger"></i>
        </IconButton>
                    <IconButton component="button"
                        onClick={() => {
                            handleClickOpen(row._id);
                        }}>
                        <i className="icofont-ui-delete text-danger"></i>
                    </IconButton>
                </div>
            ),
        },
    ];

    const rows = Array.isArray(course.data) ? course.data?.map((item, index) => ({
        id: index + 1,
        title: item?.title ?? '-',
        batchDetails: !item?.isDraft && item?.batchDetails?.length > 0 
        ? `${item?.batchDetails?.length}` 
        : item?.isDraft && item?.step >= 2 
        ? `${item?.batchDetails?.length}`
        : '',
        tutorsName: item?.tutorsName ?? '-',
        status: item?.isDraft ? 'Draft' : 'Uploaded',
        _id: item?._id,
    })) : [];

    
    const filteredRows = rows.filter(row => {
        if (activeTab === 0) return row.status === 'Uploaded';  
        if (activeTab === 1) return row.status === 'Draft';    
        return true;
    });

    return (
        <div className="body d-flex py-1">
            <div className="container-xxl">
                <PageHeader1 righttitle='Add Course' link='/course-add' routebutton={true} />
                
              
                <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}  sx={{ gap: 10 }} >
                    <Tab label="Course Upload" />
                    <Tab label="Draft" />
                    
                </Tabs>

                <div className="row g-0 mb-3 mt-4">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <Box sx={{ width: 1 }}>
                                    <Box sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <DataGrid
                                                columns={columns}
                                                rows={filteredRows}
                                                disableColumnFilter
                                                disableDensitySelector
                                                slots={{ toolbar: GridToolbar }}
                                                slotProps={{ toolbar: { showQuickFilter: true } }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this course?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CourseList;
