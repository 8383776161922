import React from 'react';
import PageHeader1 from '../../components/common/PageHeader1';
import Astrologer from '../../components/Astrologer/AstrolgerAdd';

function AstrologerAdd() {
    return (
        <div className="body d-flex py-lg-3 py-md-2">
            <div className="container-xxl">
                {/* <PageHeader1 pagetitle='Personal Information' /> */}
                <div className="row clearfix g-3">
                    <div className="col-lg-12">
                        <Astrologer />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AstrologerAdd;