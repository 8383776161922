import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCommission } from '../../Redux/Reducers/CommissionReducer';
import { editFAQ } from '../../Redux/Reducers/FAQReducer';
import axios from 'axios';

function FAQEdit() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const commission = useSelector((state) => state?.CommissionReducer?.commission);
    const faq = useSelector((state) => state?.FAQReducer?.faq);

    let localData = JSON.parse(localStorage.getItem("vedhvidhi-admin-data"));

    const [faqs, setFaqs] = useState([{ question: "", answer: "" }]);

    useEffect(() => {
        dispatch(fetchCommission(localData?._id));
        dispatch(editFAQ(id));
    }, [dispatch]);

    const [selectedCategoryId, setSelectedCategoryId] = useState(faq?.categoryId?._id || '');

    useEffect(() => {
        if (faq) {
            setSelectedCategoryId(faq?.categoryId?._id);
            setValue('categoryId', faq?.categoryId?._id);
            setValue('subCategoryId', faq?.subCategoryId?._id);
            setFaqs(faq?.faqs?.length ? faq.faqs : [{ question: "", answer: "" }]);
        }
    }, [faq, setValue]);

    const handleCategoryChange = (event) => {
        setSelectedCategoryId(event.target.value);
    };

    const filteredSubCategories = commission.filter(subCategory => subCategory.categoryId?._id === selectedCategoryId);

    const handleAddFaq = () => {
        setFaqs([...faqs, { question: "", answer: "" }]);
    };

    const handleRemoveFaq = (index) => {
        const newFaqs = faqs.filter((_, i) => i !== index);
        setFaqs(newFaqs);
    };

    const handleFaqChange = (index, key, value) => {
        const newFaqs = faqs.map((faq, i) =>
            i === index ? { ...faq, [key]: value } : faq
        );
        setFaqs(newFaqs);
    };

    const onSubmit = (data) => {
        data.sellerId = localData?._id;
        data.faqs = faqs;
        const config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASEURL}/faq?id=${id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        axios.request(config)
            .then((response) => {
                toast?.success(response?.data?.message);
                if (response?.data?.status) {
                    navigate("/faq");
                }
            })
            .catch((error) => {
                console.log(error);
                toast?.error(error?.response?.data);
            });
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={true} duration="10000" />
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold">Edit information</h6>
            </div>
            <div className="card-body">
                <form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3 align-items-center">
                        <div className="col-md-6">
                            <label className="form-label">Category</label>
                            <span style={{ color: "red" }}>*</span>
                            <select
                                className="form-select"
                                {...register("categoryId", { required: "Category is required" })}
                                onChange={handleCategoryChange}
                                defaultValue={faq?.categoryId?._id || ''}
                            >
                                <option value="">Select a Category</option>
                                {[...new Map(
                                    commission?.map((item) => [item?.categoryId?._id, item])
                                ).values()]?.map((filteredItem, index) => (
                                    <option key={index} value={filteredItem?.categoryId?._id}>
                                        {filteredItem?.categoryId?.categoryName}
                                    </option>
                                ))}
                            </select>
                            {errors.categoryId && <p style={{ color: "red" }}>{errors.categoryId.message}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Sub Category</label>
                            <span style={{ color: "red" }}>*</span>
                            <select
                                className="form-select"
                                {...register("subCategoryId", { required: "Sub Category is required" })}
                                defaultValue={faq?.subCategoryId?._id || ''}
                            >
                                <option value="">Select a Sub Category</option>
                                {filteredSubCategories.map((subCategory, index) => (
                                    <option key={index} value={subCategory?.subCategoryId?._id}>{subCategory?.subCategoryId?.subCategoryName}</option>
                                ))}
                            </select>
                            {errors.subCategoryId && <p style={{ color: "red" }}>{errors.subCategoryId.message}</p>}
                        </div>

                        {faqs?.map((item, index) => (
                            <div className="row g-3 align-items-center" key={index}>
                                <div className="col-md-10">
                                    <label className="form-label">FAQ {index + 1}</label>
                                    <span style={{ color: "red" }}>*</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Question'
                                        value={item?.question}
                                        onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                                    />
                                    <br />
                                    <textarea
                                        className="form-control"
                                        placeholder='Answer'
                                        value={item?.answer}
                                        onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                                    />
                                </div>

                                <div className="col-md-2 align-self-end">
                                    {index === 0 ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary w-100 text-uppercase px-3"
                                            onClick={handleAddFaq}
                                        >
                                            +
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-danger w-100 text-uppercase px-3"
                                            onClick={() => handleRemoveFaq(index)}
                                        >
                                            <b style={{ color: "#fff" }}>-</b>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-4">
                        <button type="submit" className="btn btn-primary text-uppercase px-3">SAVE</button>
                        <Link to="/faq" className="btn btn-primary text-uppercase px-3 mx-2">Cancel</Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default FAQEdit;