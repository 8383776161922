import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import menu from '../Data/Menu/menu.json';
import menu2 from '../Data/Menu/menu2.json';
import { Toaster } from 'react-hot-toast';

function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const [menuData, setMenuData] = useState([...menu.menu]);

    const [activeKey, setActiveKey] = useState(location.pathname);

    useEffect(() => {
        setActiveKey(location.pathname);
    }, [location]);

    const openChildren = (id) => {
        const otherTabs = document.getElementsByClassName("has-children");
        if (otherTabs) {
            for (let i = 0; i < otherTabs.length; i++) {
                if (otherTabs[i].id !== id) {
                    otherTabs[i].className = otherTabs[i].className.replace(" show", "");
                    if (otherTabs[i].parentElement.children.length > 1) {
                        otherTabs[i].parentElement.children[0].setAttribute("aria-expanded", "false");
                    }
                }
            }
        }
        const menutab = document.getElementById(id);
        if (menutab) {
            if (menutab.classList.contains("show")) {
                menutab.classList.remove("show");
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute("aria-expanded", "false");
                }
            } else {
                menutab.classList.add("show");
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute("aria-expanded", "true");
                }
            }
        }
    };

    const openChildren1 = (id) => {
        const otherTabs = document.getElementsByClassName("has-children");
        if (otherTabs) {
            for (let i = 0; i < otherTabs.length; i++) {
                otherTabs[i].className = otherTabs[i].className.replace(" show", "");
            }
        }
        const menutab = document.getElementById(id);
        if (menutab) {
            menutab.classList.add("show");
            if (menutab.parentElement.children.length > 1) {
                menutab.parentElement.children[0].setAttribute("aria-expanded", "true");
            }
        }
    };

    const GotoChangeMenu = (val) => {
        if (val === "UI Components") {
            navigate("ui-alerts");
            setMenuData([...menu2.menu2]);
        } else {
            navigate("dashboard");
            setMenuData([...menu.menu]);
        }
    };

    const handleClick = (route) => {
        setActiveKey(route);
        navigate(route);
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={true} duration="10000" />
            <div id="mainsidemenu" className={`sidebar px-4 py-4 py-md-4 me-0 ${isSidebarMini ? "sidebar-mini" : ""}`} style={{ overflow: 'scroll' }}>
                <div className="d-flex flex-column h-100">
                    <Link to="/dashboard" className="mb-0 brand-icon">
                        <span className="logo-icon">
                            <i className="bi bi-bag-check-fill fs-4"></i>
                        </span>
                        <span className="logo-text">Vedh-Vidhi</span>
                    </Link>
                    <ul className="menu-list flex-grow-1 mt-3">
                        {menuData?.map((d, i) => {
                            if (d.isToggled) {
                                return (
                                    <li key={`shsdg${i}`}>
                                        <a className="m-link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                GotoChangeMenu(d.name);
                                            }}>
                                            <i className={d.iconClass}></i>
                                            <span>{d.name}</span>
                                        </a>
                                    </li>
                                );
                            }
                            if (d.children?.length === 0) {
                                return (
                                    <li key={`dsfshsdg${i}`} className="collapsed">
                                        <Link to={process.env.PUBLIC_URL + "/" + d.routerLink[0]}
                                            className={`m-link ${(activeKey === process.env.PUBLIC_URL + "/" + d.routerLink[0]) ? "active" : ""}`}
                                            onClick={() => handleClick(process.env.PUBLIC_URL + "/" + d.routerLink[0])}>
                                            <i className={d.iconClass}></i>
                                            <span>{d.name}</span>
                                        </Link>
                                    </li>
                                );
                            }
                            return (
                                <li key={`shsdg${i}`} className="collapsed">
                                    <a className={`m-link ${d.children?.some(c => process.env.PUBLIC_URL + "/" + c.routerLink[0] === activeKey) ? "active" : ""}`} href="#!"
                                        onClick={(e) => {
                                            openChildren(`menu-Pages${i}`);
                                        }}>
                                        <i className={d.iconClass}></i>
                                        <span>{d.name}</span>
                                        <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                                    </a>
                                    {d.children.length > 0 &&
                                        <ul className="sub-menu collapse has-children" id={`menu-Pages${i}`}>
                                            {d.children.map((data, ind) => {
                                                if (activeKey === process.env.PUBLIC_URL + "/" + data.routerLink[0]) {
                                                    setTimeout(() => {
                                                        openChildren1(`menu-Pages${i}`);
                                                    }, 500);
                                                }
                                                return (
                                                    <li key={`jfdgj${ind}`}>
                                                        <Link
                                                            className={`${activeKey === process.env.PUBLIC_URL + "/" + data.routerLink[0] ? "ms-link active" : "ms-link"}`}
                                                            to={process.env.PUBLIC_URL + "/" + data.routerLink[0]}
                                                            onClick={() => handleClick(process.env.PUBLIC_URL + "/" + data.routerLink[0])}>
                                                            <span>{data?.name}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }
                                </li>
                            );
                        })}
                    </ul>

                    <button type="button" className="btn btn-link sidebar-mini-btn text-light" onClick={() => { setIsSidebarMini(!isSidebarMini) }}>
                        <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                    </button>
                </div>
            </div>
        </>
    );
}

export default Sidebar;