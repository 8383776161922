import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudent } from '../../Redux/Reducers/StudentReducer';
// import { useRouter } from 'next/router'; 

function StudentView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.StudentReducer?.students);
    // const router = useRouter(); 
    console.log(user,"jdhjkhhkdhkdshk")

    useEffect(() => {
        dispatch(fetchStudent(id));
    }, [dispatch, id]);

    const renderKeyValue = (key, value) => (
        <div className="row mb-2">
            <div className="col-sm-3">
                <strong>{key}:</strong>
            </div>
            <div className="col-sm-9">
                <span>{value || 'NA'}</span>
            </div>
        </div>
    );

    return (
        <div className="container mt-4">
            {/* <button
                className="btn btn-primary mb-3"
                onClick={() => router.push('/student-management')} 
            >
                Back
            </button> */}

            <h3>User Information</h3>

            <div className="row">
                {user?.data?.enrollments?.map((enrollment, index) => (
                    <div key={index} className="col-12">
                        <h5>{`Enrollment ${index + 1}`}</h5>
                        {renderKeyValue('Full Name', enrollment?.userId?.fullName)}
                        {renderKeyValue('Email', enrollment?.userId?.email)}
                        {renderKeyValue('Mobile Number', enrollment?.userId?.mobileNumber)}
                        {renderKeyValue('Date of Birth', enrollment?.userId?.dob)}
                        {renderKeyValue('Order ID', enrollment?.razorPayId)}
                        {renderKeyValue(
                            'Date Of Purchase',
                            new Date(enrollment?.date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }) || 'NA'
                        )}
                    </div>
                ))}
            </div>

            <hr />

            <h3>Enrollments</h3>
            <div className="row">
                {user?.data?.enrollments?.map((enrollment, index) => (
                    <div key={index} className="col-12 mb-4">
                        <h5>{`Enrollment ${index + 1}`}</h5>
                        {renderKeyValue('Course Title', enrollment?.courseId?.title)}
                        {renderKeyValue('Batch Name', enrollment?.batchName)}
                        {renderKeyValue('Price', `$${enrollment?.price || 'NA'}`)}
                        {renderKeyValue(
                            'Enrollment Date',
                            enrollment?.date?.split('T')?.[0] || 'NA'
                        )}
                        {renderKeyValue('Course Image', (
                            <img
                                src={enrollment?.courseId?.courseImg || 'NA'}
                                alt="Course"
                                style={{ width: '100px' }}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StudentView;
