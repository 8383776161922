import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    instructors: [], 
    status: 'idle', 
    error: null, 
};



export const fetchInstructorLead = createAsyncThunk(
    'instructorLead/fetchInstructorLead',
    async (_, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("vedhvidhi-admin-token");
            const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/instructor`, {
                headers: {
                    'Authorization': token,
                },
            });
            if (!response.ok) throw new Error('Failed to fetch Instructor data');
            const data = await response.json();
            console.log(data, "Instructor Data");
            return data?.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const postInstructorLead = createAsyncThunk(
    'instructorLead/postInstructorLead',
    async ({ name, email, phone }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("vedhvidhi-admin-token");
            const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/instructor`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({ name, email, phone }),
            });
            if (!response.ok) throw new Error('Failed to post Instructor data');
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const instructorLeadSlice = createSlice({
    name: 'instructorLead',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInstructorLead.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchInstructorLead.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.instructors = action.payload; 
            })
            .addCase(fetchInstructorLead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            
            .addCase(postInstructorLead.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postInstructorLead.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.instructors.push(action.payload); 
            })
            .addCase(postInstructorLead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default instructorLeadSlice.reducer;
